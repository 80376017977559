import {useTheme, styled, alpha} from '@mui/material'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {Legend, LegendItem} from '../../../../shared/components/Legend'
import {useMediaQueryBreakpoints} from '../../../../shared/hooks/useMediaQueryBreakpoints'

const StyledLegendLine = styled('line')({
  pointerEvents: 'none',
  fill: 'none',
  strokeWidth: '2px'
})

interface ECChartLegendProps {
  showPurchasedInfo: boolean
}

export const ECChartLegend: FC<ECChartLegendProps> = React.memo(({showPurchasedInfo}) => {
  const {t} = useTranslation()
  const {palette} = useTheme()
  const {sm: isMobile} = useMediaQueryBreakpoints()
  const lineLegendWidth = isMobile ? 20 : 40

  return (
    <Legend sx={{justifyContent: 'flex-end'}}>
      {showPurchasedInfo && (
        <>
          <LegendItem
            label={t('planning.purchasedAndBlocked', {unit: t('common.unit.MW')})}
            indicator={{
              variant: 'circle',
              style: {
                backgroundColor: alpha(palette.grey[600], 0.5),
                border: `1px solid ${palette.grey[600]}`
              }
            }}
          />
          <LegendItem
            label={t('planning.purchased', {unit: t('common.unit.MW')})}
            indicator={{
              variant: 'circle',
              style: {
                backgroundColor: alpha(palette.primary.light, 0.5),
                border: `1px solid ${palette.secondary.dark}`
              }
            }}
          />
        </>
      )}
      <LegendItem
        label={t('planning.peakLoad')}
        indicator={{
          variant: 'circle',
          style: {
            backgroundColor: alpha(palette.error.light, 0.2),
            border: `1px solid ${palette.error.light}`
          }
        }}
      />
      <LegendItem label={t('planning.planned', {unit: t('common.unit.MW')})}>
        <svg height={5} width={lineLegendWidth}>
          <StyledLegendLine
            x1={0}
            y1={3}
            x2={lineLegendWidth}
            y2={3}
            stroke={palette.primary.light}
          />
        </svg>
      </LegendItem>
      <LegendItem label={t('common.now')}>
        <svg height={5} width={lineLegendWidth}>
          <StyledLegendLine
            x1={0}
            y1={3}
            x2={lineLegendWidth}
            y2={3}
            stroke={palette.text.primary}
          />
        </svg>
      </LegendItem>
    </Legend>
  )
})

ECChartLegend.displayName = 'ECChartLegend'
