import {dataTestId} from '@hconnect/uikit'
import {Stack, Collapse} from '@mui/material'
import moment, {Moment} from 'moment-timezone'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {CollapsibleCard} from '../../../../shared/components/CollapsibleCard'
import {PlannerFeature, PlannerLSSettingsKeys} from '../../../../shared/enums'
import {useElectricityQuery, usePeakLoadWindowsQuery} from '../../../../shared/hooks/api'
import {useElectricityPricesDataQuery} from '../../../../shared/hooks/api/dataScience/useElectricityPricesDataQuery'
import {usePersistingSettingState} from '../../../../shared/hooks/usePersistingSettingState'
import {usePlannerFeature} from '../../../../shared/hooks/usePlannerFeature'
import {usePlantConfig} from '../../../../shared/hooks/usePlantConfigData'
import {getHourlyPowerDiff, getPeakLoadWindows} from '../../../../shared/selectors/electricity'
import {getLocalizedDayMonthTimeFormat} from '../../../../shared/selectors/time'

import {ECChartLegend} from './ECChartLegend'
import {ElectricityChart} from './ElectricityChart'
import {EnergyProviderButton} from './EnergyProviderButton'
import {SubmitPlanSection} from './SubmitPlanSection'

interface PlanningElectricityChartCardProps {
  startOfPlan: Moment
  endOfPlan: Moment
  hoursList: Moment[]
  cellWidth: number
  cellsDisplayed: number
  labelColumnWidth: number
}

const _PlanningElectricityChartCard: React.FC<PlanningElectricityChartCardProps> = ({
  startOfPlan,
  endOfPlan,
  hoursList,
  cellWidth,
  labelColumnWidth,
  cellsDisplayed
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const {timezone_id: timezoneId} = usePlantConfig()
  const chartWidth = cellWidth * cellsDisplayed
  const {data: electricity} = useElectricityQuery({timeFrame: [startOfPlan, endOfPlan]})
  const {data: peakLoadWindows} = usePeakLoadWindowsQuery([startOfPlan, endOfPlan])

  const powerConsumptionQuarterly = electricity?.planned

  const peakLoadWindowsData = useMemo(
    () =>
      powerConsumptionQuarterly && peakLoadWindows
        ? getPeakLoadWindows({
            peakLoadWindows,
            powerConsumption: powerConsumptionQuarterly
          })
        : undefined,
    [peakLoadWindows, powerConsumptionQuarterly]
  )

  const canPurchaseElectricity = usePlannerFeature(PlannerFeature.ElectricityPurchasing)
  const {data: electricityPrices} = useElectricityPricesDataQuery([startOfPlan, endOfPlan])

  const actualPrices = electricityPrices?.actual ?? []
  const actualPricesEnd = actualPrices.length
    ? actualPrices[actualPrices.length - 1].datetime
    : undefined

  const [energyPurchased, energyUpForPurchase, totalEnergyPurchased] = useMemo(
    () =>
      canPurchaseElectricity
        ? [electricity?.purchased, electricity?.upForPurchasing, electricity?.submitted]
        : [],
    [electricity, canPurchaseElectricity]
  )

  const [minValue, maxValue] = useMemo(() => {
    const powerValues = powerConsumptionQuarterly?.map((item) => item.power)
    return powerValues ? [Math.min(...powerValues), Math.max(...powerValues)] : [0, 1]
  }, [powerConsumptionQuarterly])

  const hourlyDiff = useMemo(
    () =>
      powerConsumptionQuarterly && totalEnergyPurchased
        ? getHourlyPowerDiff({
            hours: hoursList,
            purchasedQuarterlyPower: totalEnergyPurchased,
            plannedQuarterlyPower: powerConsumptionQuarterly
          })
        : undefined,
    [powerConsumptionQuarterly, totalEnergyPurchased, hoursList]
  )

  const [{isElectricityChartOpen}, setIsElectricityChartOpen] = usePersistingSettingState(
    PlannerLSSettingsKeys.PlanningElectricityChartSettings,
    {isElectricityChartOpen: true}
  )

  const toggleIsElectricityChartMinimized = () =>
    setIsElectricityChartOpen({isElectricityChartOpen: !isElectricityChartOpen})

  const electricityChartHeight = maxValue > 2 ? 200 : 100

  const electricitySubmittedAt = electricity?.lastSubmitted?.submittedAt
  const electricitySubmittedBy = electricity?.lastSubmitted?.submittedBy

  return (
    <CollapsibleCard
      isCollapsed={!isElectricityChartOpen}
      onToggleCollapsed={toggleIsElectricityChartMinimized}
      title={t('planning.mwPlanned')}
      subtitle={
        electricitySubmittedAt &&
        electricitySubmittedBy &&
        t('planning.lastSubmitted', {
          date: getLocalizedDayMonthTimeFormat(
            moment.utc(electricitySubmittedAt),
            timezoneId,
            language
          ),
          name: electricitySubmittedBy
        })
      }
      headerContent={
        <Stack direction="row" spacing={2} sx={{justifyContent: 'flex-end'}}>
          {canPurchaseElectricity && (
            <EnergyProviderButton startOfPlan={startOfPlan} endOfPlan={endOfPlan} />
          )}
          <SubmitPlanSection
            isSubmitAvailable={canPurchaseElectricity}
            electricity={electricity}
            peakLoadWindowsData={peakLoadWindowsData}
          />
        </Stack>
      }
      {...dataTestId('electricity_chart_card')}
    >
      <Stack sx={{positin: 'relative'}} spacing={2}>
        <Collapse in={isElectricityChartOpen}>
          <ECChartLegend showPurchasedInfo={canPurchaseElectricity} />
        </Collapse>
        <ElectricityChart
          hoursList={hoursList}
          cellWidth={cellWidth}
          yAxisWidth={labelColumnWidth}
          visibleChartWidth={chartWidth}
          height={electricityChartHeight}
          powerQuarterly={powerConsumptionQuarterly}
          energyPurchased={energyPurchased}
          energyUpForPurchase={energyUpForPurchase}
          maxValue={maxValue}
          minValue={minValue}
          peakLoadWindows={peakLoadWindowsData}
          actualPricesEnd={actualPricesEnd}
          hourlyDiff={hourlyDiff}
          isMinimized={!isElectricityChartOpen}
        />
      </Stack>
    </CollapsibleCard>
  )
}

export const PlanningElectricityChartCard = React.memo(_PlanningElectricityChartCard)
