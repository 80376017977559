import {useTheme} from '@mui/material'

import {Timeframe} from '../../../../../shared/components/providers/SelectedTimeframeProvider'
import {StockDevelopmentChartSkeleton} from '../../../../../shared/components/stockDevelopmentChart/StockDevelopmentChartSkeleton'
import {useMaterialStorageDataQuery} from '../../../../../shared/hooks/api'
import {useStockDevelopmentQuery} from '../../../../../shared/hooks/api/kpi/useStockDevelopmentQuery'
import {usePlantConfig} from '../../../../../shared/hooks/usePlantConfigData'
import {getListOfDays} from '../../../../../shared/selectors/time'
import {usePlanningChartStartEnd} from '../../PlanningChartStartEndProvider'

import {DetailedStockDevelopmentChart} from './DetailedStockDevelopmentChart'

interface DetailedStockDevelopmentChartParams {
  materialId: number
  selectedTimeframe: Timeframe | undefined
  cellWidth: number
  totalSteps: number
  immediateHourOffset: number
}

export const DetailedStockDevelopmentSection = ({
  materialId,
  selectedTimeframe,
  cellWidth,
  totalSteps,
  immediateHourOffset
}: DetailedStockDevelopmentChartParams) => {
  const {spacing} = useTheme()
  const latest = usePlantConfig()
  const {startOfPlan, endOfPlan} = usePlanningChartStartEnd()

  const {data: stockDevelopment, isLoading: isStockDevelopmentLoading} = useStockDevelopmentQuery({
    materialId,
    timeframe: [startOfPlan, endOfPlan]
  })
  const {data: storageData} = useMaterialStorageDataQuery({materialId})

  const listOfDays = selectedTimeframe
    ? getListOfDays({start: startOfPlan, end: endOfPlan, additionalDays: 1})
    : undefined

  const shouldShowChartSkeleton =
    !selectedTimeframe || !listOfDays || isStockDevelopmentLoading || !stockDevelopment

  const chartHeight = spacing(50)

  return shouldShowChartSkeleton ? (
    <StockDevelopmentChartSkeleton height={chartHeight} />
  ) : (
    <DetailedStockDevelopmentChart
      timezoneId={latest.timezone_id}
      listOfDays={listOfDays}
      stockData={stockDevelopment}
      materialId={materialId}
      cellWidth={cellWidth}
      totalSteps={totalSteps}
      immediateHourOffset={immediateHourOffset}
      minTargetLevels={
        storageData && {
          week: storageData.minTargetLevel,
          weekend: storageData.minTargetLevelWeekend
        }
      }
      deadStockLevel={storageData?.deadStockLevel}
      storageCapacity={storageData?.storageCapacity}
      height={parseInt(chartHeight)}
    />
  )
}
