import {dataTestId, datetimeFormatter} from '@hconnect/uikit'
import {HPTextField, SimpleDatePicker} from '@hconnect/uikit/src/lib2'
import {MenuItem, Stack} from '@mui/material'
import {debounce} from 'lodash'
import moment from 'moment-timezone'
import React, {useMemo, useCallback, useEffect} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {PlannerNumberInput} from '../../../../shared/components/formComponents/PlannerNumberInput'
import {useSelectedMaterialOrder} from '../../../../shared/components/providers/SelectedMaterialOrderProvider'
import {AUTOSAVE_INTERVAL_MS} from '../../../../shared/constants'
import {minValidator, requiredValidator} from '../../../../shared/helpers/validators'
import {useScheduleAgreementsByVendorIdQuery} from '../../../../shared/hooks/api'
import {
  useAddMaterialOrder,
  useEditMaterialOrder
} from '../../../../shared/hooks/api/materialOrders'
import {usePlantConfig} from '../../../../shared/hooks/usePlantConfigData'
import {useUrlParam} from '../../../../shared/hooks/useUrlParam'
import type {
  EditMaterialOrder,
  MaterialOrder,
  ScheduleAgreementsByVendor,
  ScheduleAgreement
} from '../../../../shared/interfaces/api'

import {
  MaterialOrderFormState,
  checkIsScheduleAgreementValid,
  getIsDateInvalid,
  getValidScheduleAgreements,
  isScheduleAgreementWithVendor,
  validateDocumentNumber,
  validatePositionNumber,
  validateScheduledDate,
  validateVendorId
} from './materialOrderForm.helpers'

const NotSelectedValue = 'not_selected' as const

interface MaterialOrderFormProps {
  materialOrder?: MaterialOrder
  scheduleAgreements: ScheduleAgreementsByVendor[]
}

export const MaterialOrderForm: React.FC<MaterialOrderFormProps> = ({
  materialOrder,
  scheduleAgreements
}) => {
  const plantCode = useUrlParam('plantCode')
  const materialIdParam = useUrlParam('materialId')
  const materialId = Number(materialIdParam)
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const {data: scheduleAgreementsByVendorId} = useScheduleAgreementsByVendorIdQuery()
  if (!scheduleAgreementsByVendorId) {
    throw new Error('Schedule agreements should be loaded before mounting this component')
  }
  const {setSelectedMaterialOrder} = useSelectedMaterialOrder()
  const {mutate: addMaterialOrder} = useAddMaterialOrder()
  const {mutate: editMaterialOrder} = useEditMaterialOrder()

  const {timezone_id: timezoneId, created_at: planCreated} = usePlantConfig()

  const defaultOrderValues = materialOrder
    ? {
        amount: materialOrder.amount,
        scheduledDate: materialOrder.scheduledDate,
        scheduleAgreement: isScheduleAgreementWithVendor(materialOrder.scheduleAgreement)
          ? materialOrder.scheduleAgreement
          : {
              vendorId: null,
              vendorName: materialOrder.scheduleAgreement.vendorName,
              documentNumber: null,
              positionNumber: null
            }
      }
    : {
        amount: 0,
        scheduledDate: null,
        scheduleAgreement: {
          vendorId: null,
          vendorName: '',
          documentNumber: null,
          positionNumber: null
        }
      }

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: {isDirty, dirtyFields, errors},
    watch
  } = useForm<MaterialOrderFormState>({
    mode: 'all',
    shouldFocusError: false,
    defaultValues: defaultOrderValues
  })

  const onSubmit = useCallback(
    (propertiesToUpdate: typeof dirtyFields) =>
      handleSubmit(({scheduleAgreement, scheduledDate, amount}) => {
        if (!scheduledDate) {
          return
        }
        const scheduleAgreementToSubmit = isScheduleAgreementWithVendor(scheduleAgreement)
          ? scheduleAgreement
          : null
        if (materialOrder) {
          return Object.keys(propertiesToUpdate)
            .map((property) => property as keyof EditMaterialOrder)
            .forEach((property) => {
              editMaterialOrder(
                {
                  plantCode,
                  editedOrder: {
                    scheduleAgreement: scheduleAgreementToSubmit,
                    scheduledDate,
                    amount
                  },
                  materialOrderId: materialOrder.id,
                  property
                },
                {
                  onError: () => reset(),
                  onSuccess: (materialOrder) => {
                    // reseting only edited property per mutation
                    reset({[property]: materialOrder[property]}, {keepValues: true})
                  }
                }
              )
            })
        }
        addMaterialOrder(
          {
            plantCode,
            dto: {
              materialId,
              scheduleAgreement: scheduleAgreementToSubmit,
              scheduledDate,
              amount
            }
          },
          {
            onError: () => reset(),
            onSuccess: ({id, scheduleAgreement, scheduledDate, amount}) => {
              reset({
                scheduleAgreement: scheduleAgreement.vendorId
                  ? scheduleAgreement
                  : {
                      ...scheduleAgreement,
                      vendorId: null,
                      documentNumber: null,
                      positionNumber: null
                    },
                scheduledDate,
                amount
              })
              setSelectedMaterialOrder({id})
            }
          }
        )
      })(),
    [
      editMaterialOrder,
      addMaterialOrder,
      materialOrder,
      reset,
      handleSubmit,
      materialId,
      plantCode,
      setSelectedMaterialOrder
    ]
  )

  const debouncedOnSubmit = useMemo(() => debounce(onSubmit, AUTOSAVE_INTERVAL_MS), [onSubmit])

  // we need to watch this fields to rerun the autosave effect
  const currentVendorId = watch('scheduleAgreement.vendorId')
  const currentDocumentNumber = watch('scheduleAgreement.documentNumber')
  const currentPositionNumber = watch('scheduleAgreement.positionNumber')
  const currentScheduledDate = watch('scheduledDate')
  const currentAmount = watch('amount')

  useEffect(() => {
    if (isDirty) {
      void debouncedOnSubmit(dirtyFields)
    }
  }, [
    isDirty,
    dirtyFields,
    currentVendorId,
    currentDocumentNumber,
    currentPositionNumber,
    currentScheduledDate,
    currentAmount,
    debouncedOnSubmit
  ])

  const currentScheduleAgreementsByDocumentNumber = useMemo(
    () =>
      currentVendorId
        ? Object.fromEntries(
            scheduleAgreementsByVendorId[currentVendorId]?.scheduleAgreements.map(
              (scheduleAgreement) => [scheduleAgreement.documentNumber, scheduleAgreement]
            ) ?? []
          )
        : {},
    [scheduleAgreementsByVendorId, currentVendorId]
  )

  const currentScheduleAgreement: ScheduleAgreement | undefined = currentDocumentNumber
    ? currentScheduleAgreementsByDocumentNumber[currentDocumentNumber]
    : undefined
  const isVendorNotSpecified = currentVendorId === null

  return (
    <Stack spacing={2} {...dataTestId('create_edit_material_order_form')}>
      <Controller
        control={control}
        name="amount"
        rules={{...requiredValidator(t), ...minValidator(t, 1)}}
        render={({field: {ref, value, onChange}, fieldState: {error}}) => (
          <PlannerNumberInput
            inputRef={ref}
            min={0}
            value={value}
            onChange={onChange}
            error={error?.message}
            label={t('stock.materialOrders.amountTons')}
            dataTestId="material_amount_input"
          />
        )}
      />
      <Controller
        control={control}
        name="scheduledDate"
        rules={{
          ...requiredValidator(t),
          validate: validateScheduledDate({vendorId: currentVendorId, scheduleAgreements, t})
        }}
        render={({field: {value, onChange}, fieldState: {error}}) => (
          <SimpleDatePicker
            sx={{width: 1}}
            isOutsideRange={getIsDateInvalid({
              timezoneId,
              planCreated,
              scheduleAgreements,
              vendorId: currentVendorId
            })}
            textFormatter={(datetime) =>
              datetimeFormatter(datetime, language, moment.utc(datetime).tz(timezoneId).utcOffset())
            }
            handleDateChange={(date) => onChange(date?.toISOString())}
            date={value ? moment.utc(value).toDate() : null}
            errorText={error?.message}
            withForm
          />
        )}
      />
      <Controller
        control={control}
        name="scheduleAgreement.vendorId"
        rules={{
          validate: validateVendorId({
            scheduledDate: currentScheduledDate,
            scheduleAgreementsByVendorId,
            t
          })
        }}
        render={({field: {value, onChange, ref}, fieldState: {error}}) => (
          <HPTextField
            select
            value={value === null ? NotSelectedValue : value}
            onChange={({target: {value}}) => {
              setValue('scheduleAgreement.documentNumber', null)
              setValue('scheduleAgreement.positionNumber', null)
              if (value === NotSelectedValue) {
                setValue('scheduleAgreement.vendorName', 'No vendor selected')
                onChange(null)
              } else {
                setValue(
                  'scheduleAgreement.vendorName',
                  scheduleAgreementsByVendorId[value].vendorName
                )
                onChange(value)
              }
            }}
            label={t('stock.materialOrders.vendor')}
            inputRef={ref}
            error={Boolean(error?.message)}
            helperText={error?.message}
            {...dataTestId('material_vendor_select')}
          >
            <MenuItem
              key="noVendor"
              value={NotSelectedValue}
              {...dataTestId('material_vendor_option')}
            >
              {t('stock.materialOrders.vendorNotSelected')}
            </MenuItem>
            {scheduleAgreements.map(({vendorName, vendorId, scheduleAgreements}) => (
              <MenuItem
                key={vendorId}
                value={vendorId}
                {...dataTestId('material_vendor_option')}
                disabled={Boolean(
                  currentScheduledDate &&
                    !getValidScheduleAgreements(currentScheduledDate, scheduleAgreements).length
                )}
              >
                {vendorName}
              </MenuItem>
            ))}
          </HPTextField>
        )}
      />
      <Controller
        control={control}
        name="scheduleAgreement.documentNumber"
        rules={{
          validate: validateDocumentNumber({
            currentScheduleAgreementsByDocumentNumber,
            vendorId: currentVendorId,
            scheduledDate: currentScheduledDate,
            isVendorIdError: !!errors?.scheduleAgreement?.vendorId,
            t
          })
        }}
        render={({field: {value, onChange, ref}, fieldState: {error}}) => (
          <HPTextField
            select
            value={value ?? NotSelectedValue}
            onChange={(e) => {
              setValue('scheduleAgreement.positionNumber', null)
              const value = e.target.value === NotSelectedValue ? null : e.target.value
              onChange(value)
            }}
            label={t('stock.materialOrders.documentNumber')}
            inputRef={ref}
            error={Boolean(error?.message)}
            helperText={error?.message}
            disabled={isVendorNotSpecified}
            {...dataTestId('document_number_select')}
          >
            <MenuItem key="notSelected" value={NotSelectedValue}>
              {t('common.notSelected')}
            </MenuItem>
            {!isVendorNotSpecified &&
              scheduleAgreementsByVendorId[currentVendorId]?.scheduleAgreements.map(
                ({documentNumber, validFrom, validTo}) => (
                  <MenuItem
                    key={documentNumber}
                    value={documentNumber}
                    disabled={Boolean(
                      currentScheduledDate &&
                        !checkIsScheduleAgreementValid(currentScheduledDate, validFrom, validTo)
                    )}
                    {...dataTestId('document_number_option')}
                  >
                    {documentNumber}
                  </MenuItem>
                )
              )}
          </HPTextField>
        )}
      />
      <Controller
        control={control}
        name="scheduleAgreement.positionNumber"
        rules={{
          validate: validatePositionNumber({
            vendorId: currentVendorId,
            isDocumentNumberError: !!errors?.scheduleAgreement?.documentNumber,
            t
          })
        }}
        render={({field: {value, onChange, ref}, fieldState: {error}}) => (
          <HPTextField
            select
            value={value ?? NotSelectedValue}
            onChange={(e) => {
              const value = e.target.value === NotSelectedValue ? null : e.target.value
              onChange(value)
            }}
            label={t('stock.materialOrders.positionNumber')}
            inputRef={ref}
            error={Boolean(error?.message)}
            helperText={error?.message}
            disabled={isVendorNotSpecified}
            {...dataTestId('position_number_select')}
          >
            <MenuItem key="notSelected" value={NotSelectedValue}>
              {t('common.notSelected')}
            </MenuItem>
            {currentScheduleAgreement?.positionNumber.map((positionNumber) => (
              <MenuItem
                key={positionNumber}
                value={positionNumber}
                disabled={Boolean(
                  currentScheduledDate &&
                    !checkIsScheduleAgreementValid(
                      currentScheduledDate,
                      currentScheduleAgreement.validFrom,
                      currentScheduleAgreement.validTo
                    )
                )}
                {...dataTestId('position_number_option')}
              >
                {positionNumber}
              </MenuItem>
            ))}
          </HPTextField>
        )}
      />
    </Stack>
  )
}
