import {Material} from '@hconnect/common/types'
import {Card} from '@hconnect/uikit/src/lib2'
import {Skeleton} from '@mui/material'
import React from 'react'

import {dataTestId} from '@hconnect/uikit'
import {useMaterialStorageDataFromHistoryQuery} from '../../shared/hooks/api'
import {useStockDevelopmentQuery} from '../../shared/hooks/api/kpi/useStockDevelopmentQuery'
import {usePlanRange} from '../../shared/hooks/usePlanRange'
import {usePlantConfig} from '../../shared/hooks/usePlantConfigData'

import {SiloLevelsCardHeader} from './SiloLevelsCardHeader'
import {SiloLevelsChart} from './SiloLevelsChart'

interface SiloLevelsCardProps {
  material: Material
}

export const SiloLevelsCard: React.FC<SiloLevelsCardProps> = ({material}) => {
  const {timezone_id: timezoneId, created_at: createdAt} = usePlantConfig()
  const planTimeFrame = usePlanRange({timezoneId, createdAt})
  const {data: manualStockDevelopment} = useStockDevelopmentQuery({
    materialId: material.id,
    timeframe: planTimeFrame
  })
  const {data: optimizedStockDevelopment} = useStockDevelopmentQuery({
    materialId: material.id,
    timeframe: planTimeFrame,
    useOptimizedSchedule: true
  })
  const {data: storageData} = useMaterialStorageDataFromHistoryQuery({
    materialId: material.id,
    timeFrame: planTimeFrame
  })
  if (!storageData) {
    throw new Error('Storages should be loaded before rendering this component')
  }

  const {storageCapacity, minTargetLevel, minTargetLevelWeekend} = storageData

  return (
    <Card
      {...dataTestId('silo_levels_card')}
      sx={{height: '100%'}}
      headerContent={<SiloLevelsCardHeader material={material} />}
    >
      {manualStockDevelopment && optimizedStockDevelopment ? (
        <SiloLevelsChart
          range={planTimeFrame}
          manualStock={manualStockDevelopment.stockLevels}
          optimizedStock={optimizedStockDevelopment.stockLevels}
          storageCapacity={storageCapacity}
          minTargetLevel={minTargetLevel}
          minTargetLevelWeekend={minTargetLevelWeekend}
        />
      ) : (
        <Skeleton variant="rectangular" height={280} />
      )}
    </Card>
  )
}
