import {formatTons} from '@hconnect/uikit/src/common'
import {useTheme} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {ChartGrid, ChartPath, DatetimeValueChartLabels} from '../../shared/components/d3chart'
import {useResizableChartWidth} from '../../shared/components/d3chart/utils'
import {dataTestId} from '@hconnect/uikit'
import {useDateScale, useLinearScale} from '../../shared/helpers/scale'
import {calculateNiceAxisSteps} from '../../shared/helpers/utils'
import {usePlantConfig} from '../../shared/hooks/usePlantConfigData'
import {StockDevelopmentEntry} from '../../shared/interfaces/api'
import {DatetimeValue} from '../../shared/interfaces/common'
import {HCEMPalette} from '../../shared/pallette'
import {getListOfDays, MomentRange} from '../../shared/selectors/time'

interface SiloLevelsChartProps {
  range: MomentRange
  manualStock: StockDevelopmentEntry & {combined: DatetimeValue[]}
  optimizedStock: StockDevelopmentEntry & {combined: DatetimeValue[]}
  storageCapacity: number
  minTargetLevel: number
  minTargetLevelWeekend: number
}

export const SiloLevelsChart: React.FC<SiloLevelsChartProps> = ({
  range,
  manualStock,
  optimizedStock,
  storageCapacity,
  minTargetLevel,
  minTargetLevelWeekend
}) => {
  const {palette} = useTheme()
  const {
    i18n: {language}
  } = useTranslation()
  const {timezone_id: timezoneId} = usePlantConfig()

  const {steps: verticalSteps} = calculateNiceAxisSteps({
    maxValue: storageCapacity,
    shouldUseExactMaxValue: true
  })
  const [ref, width] = useResizableChartWidth()
  const height = 200

  const listOfDays = getListOfDays({
    start: range[0],
    end: range[1],
    additionalDays: 1
  })

  const yScale = useLinearScale({domain: [0, storageCapacity], range: [height, 0]})

  const xScale = useDateScale({
    domain: [listOfDays[0], listOfDays[listOfDays.length - 1]],
    range: [0, width],
    isUTC: true
  })

  const minTargetLevelDatetimeValues = listOfDays.map<DatetimeValue<string>>((day) => {
    // In order to avoid DST bugs we need to add utcOffset before checking the day
    const utcOffset = day.clone().tz(timezoneId).utcOffset()
    const isEndOfWeekend = day.clone().add(utcOffset, 'minutes').day() === 0

    if (isEndOfWeekend && minTargetLevelWeekend > minTargetLevel) {
      return {
        datetime: day.clone().tz(timezoneId).add(1, 'day').toISOString(),
        value: minTargetLevelWeekend
      }
    }
    return {
      datetime: day.toISOString(),
      value: minTargetLevel
    }
  })

  return (
    <DatetimeValueChartLabels
      xSteps={listOfDays}
      ySteps={verticalSteps}
      xScale={xScale}
      yScale={yScale}
      timezoneId={timezoneId}
      yStepsFormatter={(step, index) => {
        const valueInTons = formatTons(Number(step), language)
        if (index === verticalSteps.length - 1) {
          return `${valueInTons}
          (max)`
        }
        return valueInTons
      }}
    >
      <div ref={ref} {...dataTestId('silo-levels-chart')}>
        <svg height={height} width={width} viewBox={`0 0 ${width} ${height}`}>
          <ChartGrid
            xSteps={listOfDays}
            ySteps={verticalSteps}
            xScale={xScale}
            yScale={yScale}
            overrideHorizontalLineStyles={(_, index) =>
              index === verticalSteps.length - 1 ? {stroke: palette.error.dark} : undefined
            }
          />
          <ChartPath
            // differentiate between actual and forecast if needed
            datetimeValues={manualStock.combined}
            xScale={xScale}
            yScale={yScale}
            overridePathAttributes={{stroke: HCEMPalette.brightPink}}
            fill
            testId="silo-level-manual-path"
          />
          <ChartPath
            // differentiate between actual and forecast if needed
            datetimeValues={optimizedStock.combined}
            xScale={xScale}
            yScale={yScale}
            fill
            testId="silo-level-optimizer-path"
          />
          <ChartPath
            datetimeValues={minTargetLevelDatetimeValues}
            xScale={xScale}
            yScale={yScale}
            overridePathAttributes={{
              stroke: HCEMPalette.orangePeach,
              strokeDasharray: '8,5',
              strokeWidth: '1px'
            }}
            testId="min_target_level_line"
          />
        </svg>
      </div>
    </DatetimeValueChartLabels>
  )
}
