import {DragIndicator} from '@mui/icons-material'
import {styled, lighten} from '@mui/material'
import React, {MouseEventHandler, TouchEventHandler} from 'react'

import {dataTestId} from '../../../../common/utils/testingUtils'

import {useWindowListener} from './hooks/useWindowListener'
import type {FormatLabelFn} from './Label'
import type {NumberRange} from './utils'

const StyledSliderContainer = styled('span', {shouldForwardProp: (prop) => prop !== 'percentage'})<{
  percentage: number
}>(({percentage}) => ({
  position: 'absolute',
  height: 46,
  top: 1,
  left: `${percentage * 100}%`
}))

const StyledSliderDiv = styled('div', {shouldForwardProp: (prop) => prop !== 'type'})<{
  type: SliderKey
}>(({theme, type}) => ({
  height: '100%',
  width: 8,
  background: theme.palette.primary.main,
  boxShadow: theme.shadows[8],
  cursor: 'ew-resize',
  transition: 'background ease-in-out 100ms',
  '&:hover': {
    background: lighten(theme.palette.primary.main, 0.2)
  },
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  ...(type === 'min' && {marginInlineStart: 0, borderTopLeftRadius: 6, borderBottomLeftRadius: 6}),
  ...(type === 'max' && {
    marginInlineStart: -8,
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6
  })
}))

const StyledDragIndicator = styled(DragIndicator)(({theme}) => ({
  color: theme.palette.common.white,
  opacity: 0.5,
  '&:hover': {
    opacity: 1
  }
}))

export type SliderKey = 'min' | 'max'

export interface SliderProps {
  ariaLabelledBy?: string
  ariaControls?: string
  formatLabel?: FormatLabelFn
  minMax: NumberRange
  value: number
  onSliderDrag: (event: MouseEvent | TouchEvent, type: SliderKey) => void
  onSliderStart: () => void
  percentage: number
  type: SliderKey
}
export const Slider: React.FC<SliderProps> = React.memo(
  ({
    type,
    percentage,
    minMax,
    value,
    onSliderDrag,
    onSliderStart,
    ariaControls,
    ariaLabelledBy
  }) => {
    const [minVal, maxVal] = minMax

    // Global event handlers for movement
    const [addMouseMove, removeMouseMove] = useWindowListener('mousemove', (event) =>
      onSliderDrag(event, type)
    )
    const [addTouchMove, removeTouchMove] = useWindowListener('touchmove', (event) =>
      onSliderDrag(event, type)
    )

    const [addTouchEnd] = useWindowListener('touchend', () => removeTouchMove())
    const [addMouseUp] = useWindowListener('mouseup', () => removeMouseMove())

    const handleTouchStart: TouchEventHandler<HTMLDivElement> = (event) => {
      event.stopPropagation()
      onSliderStart()
      addTouchEnd({once: true})
      addTouchMove()
    }

    const handleMouseDown: MouseEventHandler<HTMLDivElement> = (event) => {
      event.stopPropagation()
      onSliderStart()
      addMouseMove()
      addMouseUp({once: true})
    }
    return (
      <StyledSliderContainer percentage={percentage}>
        <StyledSliderDiv
          type={type}
          aria-labelledby={ariaLabelledBy}
          aria-controls={ariaControls}
          aria-valuemax={maxVal}
          aria-valuemin={minVal}
          aria-valuenow={value}
          draggable="false"
          onMouseDown={handleMouseDown}
          onTouchStart={handleTouchStart}
          role="slider"
          {...dataTestId(`slider-${type}`)}
          tabIndex={0}
        >
          <StyledDragIndicator type={type} />
        </StyledSliderDiv>
      </StyledSliderContainer>
    )
  }
)
