import React, {useMemo, useLayoutEffect, useCallback} from 'react'

import {SelectedTimeframeProvider} from '../../../shared/components/providers/SelectedTimeframeProvider'
import {PlannerSkeleton} from '../../../shared/components/skeletons/PlannerSkeleton'
import {PlannerLSSettingsKeys} from '../../../shared/enums'
import {
  convertToURLDatetime,
  parseStartEndDatetimeFromURLParams
} from '../../../shared/helpers/urlParams.utils'
import {
  adjustRangeByMinMaxLimitAndBoundary,
  calculateDatetimeRangeWithinBoundary
} from '../../../shared/helpers/utils'
import {useLongTermPlanBoundary} from '../../../shared/hooks/useLongTermPlanBoundary'
import {usePlannerUISettings} from '../../../shared/hooks/usePlannerUISettings'
import {usePlanRange} from '../../../shared/hooks/usePlanRange'
import {usePlantConfig} from '../../../shared/hooks/usePlantConfigData'
import {useSearchParams} from '../../../shared/hooks/useSearchParams'
import {MomentRange} from '../../../shared/selectors/time'
import {LayoutDailyPlanning} from '../LayoutDailyPlanning'

const LONG_TERM_PLANNING_DAYS = 31

const MIN_RANGE_HOURS = 24
const MAX_RANGE_HOURS = 24 * 7

const getDailyTimeframeFromSelectedRange = (
  range: MomentRange,
  timezoneId: string
): MomentRange => {
  const startOfTimeframe = range[0].clone().tz(timezoneId).startOf('day')
  return [startOfTimeframe, startOfTimeframe.clone().add(LONG_TERM_PLANNING_DAYS, 'days')]
}

export const PageDailyProductionPlanning: React.FC = () => {
  const {timezone_id: timezoneId, created_at: createdAt} = usePlantConfig()
  // boundary to set limits of scroll back and forward
  const boundary = useLongTermPlanBoundary()

  // we need to infer full timeframe from a selected range from URL
  // TODO abstract it to hook or fn getTimeFrameFromUrlParams
  const [obtainedParams, setSearchParams] = useSearchParams('start', 'end')

  const parsedSelectedRange = useMemo(() => {
    const selectedRange = parseStartEndDatetimeFromURLParams({...obtainedParams, timezoneId})
    const adjustedRange = selectedRange
      ? adjustRangeByMinMaxLimitAndBoundary(selectedRange, boundary, [
          MIN_RANGE_HOURS,
          MAX_RANGE_HOURS
        ])
      : undefined
    return adjustedRange
  }, [obtainedParams, boundary, timezoneId])

  const defaultTimeframe = usePlanRange({days: LONG_TERM_PLANNING_DAYS, timezoneId, createdAt})

  // get timeframe from url at this point, if not exist, use default timeframe
  const timeframe = parsedSelectedRange
    ? calculateDatetimeRangeWithinBoundary(
        getDailyTimeframeFromSelectedRange(parsedSelectedRange, timezoneId),
        boundary
      )
    : defaultTimeframe

  // slider default range
  const {settings: sliderSettings, updateSettings: updateSliderSetting} = usePlannerUISettings(
    PlannerLSSettingsKeys.PlanningSliderSettings,
    {defaultRange: 24}
  )

  const sliderDefaultRange = sliderSettings.defaultRange

  const updateSliderDefaultRange = useCallback(
    (defaultRange: number) => {
      updateSliderSetting({defaultRange})
    },
    [updateSliderSetting]
  )

  // if selectedRange is not set, set it to default selected range
  useLayoutEffect(() => {
    if (!parsedSelectedRange) {
      setSearchParams({
        start: convertToURLDatetime(timeframe[0], timezoneId),
        end: convertToURLDatetime(timeframe[0].clone().add(sliderDefaultRange, 'hours'), timezoneId)
      })
    }
  }, [parsedSelectedRange, setSearchParams, timeframe, sliderDefaultRange, timezoneId])

  if (!parsedSelectedRange) {
    return <PlannerSkeleton height="40vh" />
  }

  return (
    <SelectedTimeframeProvider defaultTimeframe={timeframe}>
      <LayoutDailyPlanning
        updateSliderDefaultRange={updateSliderDefaultRange}
        sliderDefaultRange={sliderDefaultRange}
        boundary={boundary}
      />
    </SelectedTimeframeProvider>
  )
}
