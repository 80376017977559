import moment from 'moment'
import 'moment-timezone'

export const isSameDate = (a?: Date | null, b?: Date | null): boolean =>
  Boolean(a && b && (a === b || moment(a).isSame(b, 'date')))

export const isSameDayAcrossTimezones = (date1: Date, date2: Date, timezone: string): boolean => {
  const normalizedDate1 = moment(date1).tz(timezone).format('YYYY-MM-DD')
  const normalizedDate2 = moment(date2).tz(timezone).format('YYYY-MM-DD')
  return normalizedDate1 === normalizedDate2
}
export const isSameMonthAcrossTimezones = (date1: Date, date2: Date, timezone: string): boolean => {
  const normalizedDate1 = moment(date1).tz(timezone)
  const normalizedDate2 = moment(date2).tz(timezone)
  return normalizedDate1.isSame(normalizedDate2, 'month')
}
