import moment from 'moment-timezone'

import {
  ScheduleAgreement,
  ScheduleAgreementDataResponse,
  ScheduleAgreementDataWithVendor,
  ScheduleAgreementsByVendor
} from '../../../../shared/interfaces/api'

// replaces optional properties with required nullable, ex: a?: string => a: string | null
type OptionalToNullable<O> = {
  [K in keyof O]-?: undefined extends O[K] ? NonNullable<O[K]> | null : O[K]
}

export interface MaterialOrderFormState {
  amount: number
  scheduledDate: string | null
  scheduleAgreement:
    | OptionalToNullable<ScheduleAgreementDataResponse>
    | ScheduleAgreementDataWithVendor
}

export const isScheduleAgreementWithVendor = (
  scheduleAgreement:
    | ScheduleAgreementDataResponse
    | OptionalToNullable<ScheduleAgreementDataResponse>
): scheduleAgreement is ScheduleAgreementDataWithVendor =>
  scheduleAgreement.vendorId !== undefined && scheduleAgreement.vendorId !== null

export const checkIsScheduleAgreementValid = (date: string, ...validRange: [string, string]) =>
  moment.utc(date).isBetween(...validRange)

export const getValidScheduleAgreements = (
  selectedDate: string,
  scheduleAgreements: ScheduleAgreement[]
) => {
  const filteredAgreements = scheduleAgreements.filter(({validFrom, validTo}) =>
    checkIsScheduleAgreementValid(selectedDate, validFrom, validTo)
  )
  return filteredAgreements
}

type GetDateIsInvalidParams = {
  timezoneId: string
  planCreated: string
  scheduleAgreements: ScheduleAgreementsByVendor[]
  vendorId: OptionalToNullable<ScheduleAgreementDataResponse>['vendorId']
}

export const getIsDateInvalid =
  ({timezoneId, planCreated, vendorId, scheduleAgreements}: GetDateIsInvalidParams) =>
  (date: Date) => {
    const utcDate = moment.utc(date).tz(timezoneId)
    const shouldDisablePastAndToday = utcDate.isSameOrBefore(moment.utc(planCreated).tz(timezoneId))
    if (shouldDisablePastAndToday) {
      return true
    }
    if (vendorId !== null) {
      return !getValidScheduleAgreements(
        utcDate.toISOString(),
        scheduleAgreements.flatMap((vendor) => vendor.scheduleAgreements)
      ).length
    }
    return false
  }

type ValidateScheduledDateParams = {
  vendorId: OptionalToNullable<ScheduleAgreementDataResponse>['vendorId']
  scheduleAgreements: ScheduleAgreementsByVendor[]
  t: (key: string) => string
}

export const validateScheduledDate =
  ({vendorId, scheduleAgreements, t}: ValidateScheduledDateParams) =>
  (selectedDate: MaterialOrderFormState['scheduledDate']) => {
    if (
      !vendorId ||
      !selectedDate ||
      getValidScheduleAgreements(
        selectedDate,
        scheduleAgreements.flatMap((vendor) => vendor.scheduleAgreements)
      ).length
    ) {
      return true
    }
    return t('stock.materialOrders.scheduledDateError')
  }

type validateVendorIdParams = {
  scheduledDate: MaterialOrderFormState['scheduledDate']
  scheduleAgreementsByVendorId: Record<string, ScheduleAgreementsByVendor>
  t: (key: string) => string
}

export const validateVendorId =
  ({scheduledDate, scheduleAgreementsByVendorId, t}: validateVendorIdParams) =>
  (vendorId: OptionalToNullable<ScheduleAgreementDataResponse>['vendorId']) => {
    if (
      !scheduledDate ||
      vendorId === null ||
      !scheduleAgreementsByVendorId[vendorId]?.scheduleAgreements ||
      getValidScheduleAgreements(
        scheduledDate,
        scheduleAgreementsByVendorId[vendorId].scheduleAgreements
      ).length
    ) {
      return true
    }
    return t('stock.materialOrders.invalidVendorError')
  }

type ValidateDocumentNumberParams = {
  currentScheduleAgreementsByDocumentNumber: Record<string, ScheduleAgreement>
  vendorId: OptionalToNullable<ScheduleAgreementDataResponse>['vendorId']
  isVendorIdError: boolean
  scheduledDate: MaterialOrderFormState['scheduledDate']
  t: (key: string) => string
}

export const validateDocumentNumber =
  ({
    currentScheduleAgreementsByDocumentNumber,
    vendorId,
    scheduledDate,
    isVendorIdError,
    t
  }: ValidateDocumentNumberParams) =>
  (documentNumber) => {
    if (vendorId === null || !scheduledDate) {
      return true
    }
    if (documentNumber === null) {
      return t('error.required')
    }
    const {validFrom, validTo} = currentScheduleAgreementsByDocumentNumber[documentNumber]
    return isVendorIdError || !checkIsScheduleAgreementValid(scheduledDate, validFrom, validTo)
      ? t('stock.materialOrders.invalidDocumentNumber')
      : true
  }

type ValidatePositionNumberParams = {
  vendorId: OptionalToNullable<ScheduleAgreementDataResponse>['vendorId']
  isDocumentNumberError: boolean
  t: (key: string) => string
}

export const validatePositionNumber =
  ({vendorId, isDocumentNumberError, t}: ValidatePositionNumberParams) =>
  (positionNumber: OptionalToNullable<ScheduleAgreementDataResponse>['positionNumber']) => {
    if (vendorId === null) {
      return true
    }
    if (positionNumber === null) {
      return t('error.required')
    }
    if (isDocumentNumberError) {
      return t('stock.materialOrders.invalidPositionNumber')
    }
    return true
  }
