import {StockLevelOverwrite} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {Card, CardTitle} from '@hconnect/uikit/src/lib2'
import {Box, useTheme} from '@mui/material'
import moment from 'moment-timezone'
import {useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useSelectedTimeframe} from '../../../shared/components/providers/SelectedTimeframeProvider'
import {StockOverwriteDialog} from '../../../shared/components/stockDevelopmentChart/overwrites/StockOverwriteDialog'
import {StaticStockDevelopmentChart} from '../../../shared/components/stockDevelopmentChart/StaticStockDevelopmentChart'
import {StockDevelopmentChartSkeleton} from '../../../shared/components/stockDevelopmentChart/StockDevelopmentChartSkeleton'
import {useMaterialsById, useMaterialStorageDataQuery} from '../../../shared/hooks/api'
import {useStockDevelopmentQuery} from '../../../shared/hooks/api/kpi/useStockDevelopmentQuery'
import {useIncomingMaterialsQuery} from '../../../shared/hooks/api/materialOrders/useIncomingMaterialsQuery'
import {useIsMaterialUnmeasured} from '../../../shared/hooks/api/materials/useIsMaterialUnmeasured'
import {useCreateStockLevelOverwrite} from '../../../shared/hooks/api/materialStorage/useCreateStockLevelOverwrite'
import {useStockLevelOverwritesQuery} from '../../../shared/hooks/api/materialStorage/useStockLevelOverwritesQuery'
import {usePlantConfig} from '../../../shared/hooks/usePlantConfigData'
import {useUrlParam} from '../../../shared/hooks/useUrlParam'
import {DatetimeValue} from '../../../shared/interfaces/common'
import {getIncomingMaterialHourly} from '../../../shared/selectors/materialOrders'
import {getListOfDays} from '../../../shared/selectors/time'

import {StockDevelopmentChartLegend} from './StockDevelopmentChartLegend'

export const DetailedStockDevelopmentCard = () => {
  const {t} = useTranslation()
  const materialIdParam = useUrlParam('materialId')
  const materialId = Number(materialIdParam)
  const plantCode = useUrlParam('plantCode')
  const {selectedTimeframe} = useSelectedTimeframe()

  const {spacing} = useTheme()
  const {timezone_id: timezoneId} = usePlantConfig()

  const {data: stockDevelopment, isLoading: isStockDevelopmentLoading} = useStockDevelopmentQuery({
    materialId,
    timeframe: selectedTimeframe
  })
  const {data: incomingMaterial, isLoading: isIncomingMaterialLoading} = useIncomingMaterialsQuery({
    materialId,
    timeframe: selectedTimeframe
  })
  const {data: storageData} = useMaterialStorageDataQuery({
    materialId
  })

  const {data: materialsById} = useMaterialsById()
  const [stockOverwriteDialogParams, setStockOverwriteDialogOpen] = useState<
    {data: DatetimeValue<string, number>; overwrite: StockLevelOverwrite | undefined} | undefined
  >(undefined)
  const openStockOverwriteDialog = useCallback(
    (data: DatetimeValue<string, number>, overwrite: StockLevelOverwrite | undefined) => {
      setStockOverwriteDialogOpen({data, overwrite})
    },
    []
  )
  const {data: isMaterialUnmeasured, isLoading: isMaterialUnmeasuredIsLoading} =
    useIsMaterialUnmeasured(materialId)
  const {data: stockOverwrites} = useStockLevelOverwritesQuery({
    timeframe: selectedTimeframe,
    enabled: !isMaterialUnmeasuredIsLoading && !!isMaterialUnmeasured,
    materialId
  })
  const stockData = useMemo(() => {
    if (stockDevelopment === undefined || isMaterialUnmeasuredIsLoading) return undefined
    if (!isMaterialUnmeasured || stockOverwrites !== undefined)
      return {...stockDevelopment, stockOverwrites}
    return undefined
  }, [stockDevelopment, stockOverwrites, isMaterialUnmeasured, isMaterialUnmeasuredIsLoading])
  const {mutate: createStockLevelOverwrite, isLoading: createStockLevelOverwriteIsLoading} =
    useCreateStockLevelOverwrite()
  const onCloseStockOverwiteDialog = useCallback(() => setStockOverwriteDialogOpen(undefined), [])
  const onStockOverwrite = useCallback(
    (data: DatetimeValue<string, number>) => {
      createStockLevelOverwrite(
        {
          plantCode,
          date: data.datetime,
          level: data.value,
          materialId
        },
        {onSuccess: onCloseStockOverwiteDialog}
      )
    },
    [createStockLevelOverwrite, onCloseStockOverwiteDialog, materialId, plantCode]
  )

  const listOfDays = selectedTimeframe
    ? getListOfDays({start: selectedTimeframe[0], end: selectedTimeframe[1]})
    : undefined

  const shouldShowChartSkeleton =
    !selectedTimeframe ||
    !listOfDays ||
    isStockDevelopmentLoading ||
    isIncomingMaterialLoading ||
    !stockData
  const chartHeight = spacing(50)
  const incomingMaterialHourly = incomingMaterial && getIncomingMaterialHourly(incomingMaterial)

  const isDisabledDateEditing = useMemo(
    () =>
      !!stockData?.stockOverwrites?.find((overwrite) =>
        stockOverwriteDialogParams?.data?.datetime
          ? moment(overwrite.measuredAt)
              .tz(timezoneId)
              .isSame(moment(stockOverwriteDialogParams?.data?.datetime).tz(timezoneId))
          : false
      ),
    [stockData?.stockOverwrites, stockOverwriteDialogParams?.data?.datetime, timezoneId]
  )

  return (
    <Card
      headerContent={
        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <CardTitle variant="h3" sx={{mb: 0}}>
            {t('stock.stockDevelopment')}
          </CardTitle>
          <StockDevelopmentChartLegend />
        </Box>
      }
      {...dataTestId('stock_development_card')}
    >
      {shouldShowChartSkeleton ? (
        <StockDevelopmentChartSkeleton height={chartHeight} />
      ) : (
        <StaticStockDevelopmentChart
          timezoneId={timezoneId}
          listOfDays={listOfDays}
          stockData={stockData}
          incomingMaterialHourly={incomingMaterialHourly}
          materialId={materialId}
          openStockOverwriteDialog={isMaterialUnmeasured ? openStockOverwriteDialog : undefined}
          minTargetLevels={
            storageData && {
              week: storageData.minTargetLevel,
              weekend: storageData.minTargetLevelWeekend
            }
          }
          deadStockLevel={storageData?.deadStockLevel}
          storageCapacity={storageData?.storageCapacity}
          height={parseInt(chartHeight)}
        />
      )}
      {stockOverwriteDialogParams && materialsById && (
        <StockOverwriteDialog
          isSaving={createStockLevelOverwriteIsLoading}
          stock={stockOverwriteDialogParams?.data}
          overwrite={stockOverwriteDialogParams?.overwrite}
          material={materialsById[materialId]}
          disabledDateEditing={isDisabledDateEditing}
          onOverwrite={onStockOverwrite}
          onClose={onCloseStockOverwiteDialog}
        />
      )}
    </Card>
  )
}
