import {dataTestId} from '@hconnect/uikit'
import {NumberLetterSequenceIndicator} from '@hconnect/uikit/src/lib2'
import {Typography, Stack} from '@mui/material'
import {Moment} from 'moment-timezone'
import {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {useHistoryStoragesByIdQuery} from '../../../../../../shared/hooks/api'
import {usePlanRange} from '../../../../../../shared/hooks/usePlanRange'
import {usePlantConfig} from '../../../../../../shared/hooks/usePlantConfigData'
import type {SelectedSilos} from '../../../../../../shared/interfaces/api'

interface SelectedSilo {
  selectedSilos: SelectedSilos
  scheduleItemEnd: Moment
}

export const SelectedSilo: FC<SelectedSilo> = ({selectedSilos, scheduleItemEnd}) => {
  const {t} = useTranslation()
  const {created_at: createdAt, timezone_id: timezoneId} = usePlantConfig()
  const planTimeFrame = usePlanRange({timezoneId, createdAt})
  const {data: storagesById} = useHistoryStoragesByIdQuery({
    timeFrame: planTimeFrame,
    date: scheduleItemEnd
  })

  if (!storagesById) {
    throw new Error('BUG: storagesById query should be loaded before rendering this component')
  }
  const {isOrderSpecified, ids: selectedSiloIds} = selectedSilos

  const siloNames = selectedSiloIds.map((siloId) => storagesById[siloId].name)
  const sortedSiloNames = [...siloNames].sort((a, b) => a.localeCompare(b))

  if (siloNames.length === 0) {
    return (
      <Typography {...dataTestId('selected_silo')} variant="body1">
        {t('planning.notSelected')}
      </Typography>
    )
  }

  return (
    <>
      {(isOrderSpecified ? siloNames : sortedSiloNames).map((siloName, index) => (
        <Stack
          direction="row"
          spacing={1}
          component="span"
          {...dataTestId('selected_silo')}
          key={siloName}
        >
          {isOrderSpecified && (
            <NumberLetterSequenceIndicator numberIndex={index} {...dataTestId('silo_order')} />
          )}
          <Typography variant="body1" {...dataTestId('silo_name')}>
            {siloName}
            {index !== siloNames.length - 1 && ','}
          </Typography>
        </Stack>
      ))}
    </>
  )
}
