import {dataTestId} from '@hconnect/uikit'
import {Box, Skeleton} from '@mui/material'
import React from 'react'

import {StockCard} from '../../../app/page-stock/shared/StockCard'
import {useMaterialsById, useMaterialStorageDataQuery} from '../../../shared/hooks/api'
import {useStockDevelopmentQuery} from '../../hooks/api/kpi/useStockDevelopmentQuery'
import {usePlanRange} from '../../hooks/usePlanRange'
import {usePlantConfig} from '../../hooks/usePlantConfigData'
import {getListOfDays} from '../../selectors/time'

import {StaticStockDevelopmentChart} from './StaticStockDevelopmentChart'
import {StockDevelopmentChartSkeleton} from './StockDevelopmentChartSkeleton'

interface StockDevelopmentCardProps {
  materialId: number
}

export const SimpleStockDevelopmentCard: React.FC<StockDevelopmentCardProps> = ({materialId}) => {
  const {data: materialsById} = useMaterialsById()
  const {timezone_id: timezoneId, created_at: createdAt} = usePlantConfig()
  const planRange = usePlanRange({timezoneId, createdAt})

  const {data: stockDevelopment, isLoading} = useStockDevelopmentQuery({
    materialId,
    timeframe: planRange
  })
  const {data: storageData} = useMaterialStorageDataQuery({materialId})
  const selectedMaterial = materialsById?.[materialId]

  const listOfDays = getListOfDays({start: planRange[0], end: planRange[1]})

  const shouldShowChartSkeleton = isLoading || !stockDevelopment

  return (
    <StockCard
      page="material"
      title={
        selectedMaterial?.name ?? (
          <Skeleton variant="text" sx={{width: ({spacing}) => spacing(30)}} />
        )
      }
      material={selectedMaterial}
      {...dataTestId(`stock_development_card_${selectedMaterial?.id}`)}
    >
      <Box sx={{mt: 3}}>
        {shouldShowChartSkeleton ? (
          <StockDevelopmentChartSkeleton />
        ) : (
          <StaticStockDevelopmentChart
            stockData={stockDevelopment}
            listOfDays={listOfDays}
            materialId={materialId}
            timezoneId={timezoneId}
            minTargetLevels={
              storageData
                ? {
                    week: storageData.minTargetLevel,
                    weekend: storageData.minTargetLevelWeekend
                  }
                : undefined
            }
            storageCapacity={storageData?.storageCapacity}
          />
        )}
      </Box>
    </StockCard>
  )
}
