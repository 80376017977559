import {useMediaQuery, useTheme, Box} from '@mui/material'
import {Moment} from 'moment-timezone'
import React, {useState, useCallback, useMemo, useEffect} from 'react'

import {dataTestId as spreadDataTestId} from '../../../../common/utils/testingUtils'
import {RangeSlider} from '../RangeSlider/RangeSlider'

import {
  convertDateHourRangeToNumberRange,
  convertNumberRangeToDateHourRange,
  calculateRangeWithinBoundary
} from './dayRangeSlider.utils'
import {getDayRangeSliderActiveTrackLabel} from './DayRangeSliderActiveTrackLabel'
import {getDayRangeSliderLabel} from './DayRangeSliderLabel'

const DEFAULT_HOURS_IN_STEP = 1
const HOURS_IN_DAY = 24
const MAX_SELECTED_HOURS = 7 * 24
const MAX_SELECTED_HOURS_TABLET = 3 * 24
const MAX_SELECTED_HOURS_DESKTOP = 5 * 24

const SLIDER_MIN_STEP_WIDTH = 24

const getRangeLengthInHours = (range: [Moment, Moment]) => {
  return range[1].diff(range[0], 'hours')
}

interface DayRangeSliderProps {
  hoursInStep?: number
  setSelectedRange: (value: [Moment, Moment]) => void
  selectedRange: [Moment, Moment]
  baseRange: [Moment, Moment]
  // use this function to fire an action just before the user starts dragging the slider
  onChangeStart?: (value: [Moment, Moment]) => void
  // use this function to fire an action just after the user stops dragging the slider
  onChangeComplete?: (value: [Moment, Moment]) => void
  'data-test-id'?: string
}

export const DayRangeSlider = ({
  hoursInStep = DEFAULT_HOURS_IN_STEP,
  setSelectedRange,
  selectedRange,
  baseRange,
  onChangeComplete,
  onChangeStart,
  'data-test-id': dataTestId
}: DayRangeSliderProps) => {
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  const isDesktop = useMediaQuery(theme.breakpoints.down('lg'))

  const [startOfBaseRange] = baseRange

  const formatFn = useCallback(
    (value: number, format?: string) => {
      return startOfBaseRange
        .clone()
        .add(value, 'hours')
        .format(format ?? 'D')
    },
    [startOfBaseRange]
  )

  const [range, setRange] = useState<[number, number]>(
    convertDateHourRangeToNumberRange(selectedRange, startOfBaseRange)
  )

  useEffect(() => {
    // adjust the selected range if it is outside of the base range
    const adjustedRange = calculateRangeWithinBoundary(
      convertDateHourRangeToNumberRange(selectedRange, startOfBaseRange),
      convertDateHourRangeToNumberRange(baseRange, startOfBaseRange)
    )
    setRange(adjustedRange)
    // we need to update internal state when base range is changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseRange])

  const handleRangeChange = useCallback(
    (range: [number, number]) => {
      setSelectedRange(convertNumberRangeToDateHourRange(range, startOfBaseRange))
    },
    [setSelectedRange, startOfBaseRange]
  )

  const handleChangeStart = useCallback(
    (range: [number, number]) => {
      onChangeStart?.(convertNumberRangeToDateHourRange(range, startOfBaseRange))
    },
    [onChangeStart, startOfBaseRange]
  )

  const handleChangeComplete = useCallback(
    (range: [number, number]) => {
      onChangeComplete?.(convertNumberRangeToDateHourRange(range, startOfBaseRange))
    },
    [onChangeComplete, startOfBaseRange]
  )

  const ActiveTrackLabel = useMemo(
    () =>
      getDayRangeSliderActiveTrackLabel({
        formatStep: ([min, max], width) => {
          if (width < 15) {
            return ''
          }
          const format = width < 100 ? 'D' : 'D MMM'
          // if the range is 1 day, show only the day
          return max - min <= HOURS_IN_DAY
            ? `${formatFn(min, format)}`
            : `${formatFn(min, format)} - ${formatFn(max - 1, format)}`
        }
      }),
    [formatFn]
  )

  const LabelComponent = useMemo(() => {
    return getDayRangeSliderLabel({
      checkIsWeekend: (value) => {
        const day = startOfBaseRange.clone().add(value, 'hours')
        return day.day() === 0 || day.day() === 6
      }
    })
  }, [startOfBaseRange])

  const sliderSkipElements = useCallback((index, {sliderWidth, totalSteps}) => {
    // when width is too small we do want to skip every Nth element
    const fitsMaxSteps = sliderWidth / SLIDER_MIN_STEP_WIDTH
    const showEveryNth = Math.ceil(totalSteps / fitsMaxSteps)

    return index % showEveryNth !== 0
  }, [])

  const label = useMemo(
    () => ({
      step: HOURS_IN_DAY,
      formatFn: (value: number) => formatFn(value),
      skip: sliderSkipElements,
      LabelComponent
    }),
    [sliderSkipElements, LabelComponent, formatFn]
  )

  return (
    <Box sx={{width: '100%'}} {...spreadDataTestId(dataTestId ?? 'day_range_slider')}>
      <RangeSlider
        isDraggable
        step={hoursInStep}
        minMax={[0, getRangeLengthInHours(baseRange)]}
        values={range}
        setValues={setRange}
        onChange={handleRangeChange}
        onChangeComplete={handleChangeComplete}
        onChangeStart={handleChangeStart}
        stepLimits={{
          min: HOURS_IN_DAY,
          max: isTablet
            ? MAX_SELECTED_HOURS_TABLET
            : isDesktop
              ? MAX_SELECTED_HOURS_DESKTOP
              : MAX_SELECTED_HOURS
        }}
        label={label}
        ActiveTrackLabel={ActiveTrackLabel}
        roundClicks={{roundTo: HOURS_IN_DAY}}
      />
    </Box>
  )
}
