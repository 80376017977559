import {dateFormatter, timeFormatter, formatCurrency, formatMW} from '@hconnect/uikit/src/common'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import type {Moment} from 'moment-timezone'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {dataTestId} from '@hconnect/uikit'
import {useElectricityPricesDataQuery} from '../../../../shared/hooks/api/dataScience/useElectricityPricesDataQuery'
import {usePlantConfig} from '../../../../shared/hooks/usePlantConfigData'
import {Electricity} from '../../../../shared/interfaces/api/electricity'
import {DatetimeValue} from '../../../../shared/interfaces/common'
import {adjustEnLocale, getPlantUtcOffset} from '../../../../shared/selectors/time'
import {usePlanningChartStartEnd} from '../PlanningChartStartEndProvider'

export interface ElectricityPurchaseConfirmationProps {
  show: boolean
  onClose: (confirm: boolean) => void
  electricity: Electricity
}

function getElectricityPrice(
  prices: DatetimeValue<Moment>[],
  timeslot: string
): number | undefined {
  return prices.find((p) => p.datetime.toISOString() === timeslot)?.value
}

export const ElectricityPurchaseConfirmation: React.FC<ElectricityPurchaseConfirmationProps> = ({
  show,
  onClose,
  electricity
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const {upForPurchasing, planned: plannedPowerConsumption} = electricity
  const {timezone_id: timezoneId, currency} = usePlantConfig()
  const {startOfPlan, endOfPlan} = usePlanningChartStartEnd()
  const {data: electricityPrices} = useElectricityPricesDataQuery([startOfPlan, endOfPlan])

  const powerConsumptionDict = useMemo(() => {
    return Object.fromEntries(
      plannedPowerConsumption.map((entry) => [entry.dateTimeIso, entry.power])
    )
  }, [plannedPowerConsumption])

  if (!electricityPrices) {
    return null
  }

  // price in forecast is in price per megawatt; but we use 15minutes slots
  const purchaseData = upForPurchasing.map(({dateTimeIso, dateTimeUTC}) => {
    return {
      price: getElectricityPrice(electricityPrices.forecast, dateTimeIso),
      // we need to convert power for 15 min interval to energy in MWh
      power: powerConsumptionDict[dateTimeIso],
      // PFMnet uses right aligned intervals (so the interval 00:15 is the first of the day) but internally we use left-aligned
      datetimeUTC: dateTimeUTC.clone().add(15, 'minutes')
    }
  })

  const getTranslation = (key: string) => t(`electricity.${key}`)

  const handleReject = () => onClose(false)
  const handleConfirm = () => onClose(true)

  return (
    <Dialog
      sx={{maxHeight: '70vh'}}
      open={show}
      fullWidth
      maxWidth="md"
      onClose={handleReject}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        {...dataTestId('electricity-purchase-confirmation-modal')}
      >
        {getTranslation('submitPlan')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {getTranslation('electricityToBePurchasedLabel')}
        </DialogContentText>
      </DialogContent>
      <TableContainer component={Paper}>
        <Table sx={{maxWidth: 650}} size="small">
          <TableHead>
            <TableRow>
              <TableCell align="left">{getTranslation('periodOfTime')}</TableCell>
              <TableCell align="left">{getTranslation('power')}</TableCell>
              <TableCell align="left">{getTranslation('price')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {purchaseData.map((item) => (
              <TableRow key={`${item.datetimeUTC.toISOString()}`}>
                <TableCell
                  component="th"
                  scope="row"
                  {...dataTestId('electricity-purchasing-confirmation-datetime-cell')}
                >
                  {dateFormatter(
                    item.datetimeUTC,
                    adjustEnLocale(language),
                    getPlantUtcOffset(timezoneId, item.datetimeUTC)
                  )}
                  {', '}
                  {t('electricity.displayTime', {
                    time: timeFormatter(
                      item.datetimeUTC,
                      language,
                      getPlantUtcOffset(timezoneId, item.datetimeUTC)
                    )
                  })}
                </TableCell>
                <TableCell>{formatMW(item.power, language)}</TableCell>
                <TableCell>
                  {t('common.amountInCurrencyPerMWh', {
                    amount: item.price !== undefined ? formatCurrency(item.price, 2, language) : '',
                    currency
                  })}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DialogActions>
        <Button onClick={handleReject} variant="outlined" color="secondary">
          {t('common.cancel')}
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          color="primary"
          {...dataTestId('electricity-purchasing-confirmation-modal-ok')}
        >
          {t('common.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
