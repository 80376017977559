import {dataTestId} from '@hconnect/uikit'
import {Typography, Skeleton, Stack} from '@mui/material'
import moment from 'moment-timezone'
import React, {FC} from 'react'

import {ReactComponent as SiloIcon} from '../../../../../../assets/icons/SilosIcon.svg'
import {PlannerIcon} from '../../../../../../shared/components/PlannerIcon'
import {useHistoryStoragesByIdQuery} from '../../../../../../shared/hooks/api'
import {usePlanRange} from '../../../../../../shared/hooks/usePlanRange'
import {usePlantConfig} from '../../../../../../shared/hooks/usePlantConfigData'
import {SelectedSilos} from '../../../../../../shared/interfaces/api'

import {SelectedSilo} from './SelectedSilo'

interface SelectedSilosRow {
  selectedSilos: SelectedSilos
  // in order to get correct storages
  scheduleItemEnd: string
}

export const SelectedSilosRow: FC<SelectedSilosRow> = ({selectedSilos, scheduleItemEnd}) => {
  const {created_at: createdAt, timezone_id: timezoneId} = usePlantConfig()
  const planTimeFrame = usePlanRange({timezoneId, createdAt})
  const scheduleItemEndUTC = moment.utc(scheduleItemEnd)
  const {data: storagesById} = useHistoryStoragesByIdQuery({
    timeFrame: planTimeFrame,
    date: scheduleItemEndUTC
  })
  return (
    <Stack direction="row" spacing={0} alignItems="center" {...dataTestId('selected_silos_row')}>
      <PlannerIcon icon={<SiloIcon />} sx={{pl: 0}} />
      <Stack direction="row" alignItems="center" flexWrap="wrap" gap={2}>
        {storagesById ? (
          <SelectedSilo selectedSilos={selectedSilos} scheduleItemEnd={scheduleItemEndUTC} />
        ) : (
          <Typography variant="body1">
            <Skeleton sx={{width: ({spacing}) => spacing(5)}} />
          </Typography>
        )}
      </Stack>
    </Stack>
  )
}
