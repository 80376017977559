import {Stack} from '@mui/material'
import React from 'react'

import {prefetchPlannerQuery} from '../../shared/api/queries'
import {SelectedScheduleItemProvider} from '../../shared/components/providers/SelectedScheduleItemProvider'
import {useSelectedTimeframe} from '../../shared/components/providers/SelectedTimeframeProvider'
import {useUrlParam} from '../../shared/hooks/useUrlParam'
import {MomentRange} from '../../shared/selectors/time'

import {DailyProductionPlanningActions} from './dailyPlanning/DailyProductionPlanningActions'
import {PlanningChartScheduleDataProvider} from './dailyPlanning/PlanningChartScheduleDataProvider'
import {PlanningChartStartEndProvider} from './dailyPlanning/PlanningChartStartEndProvider'
import {PageProductionPlanningHeader} from './PageProductionPlanningHeader'
import {ProductionPlanningChartContainer} from './ProductionPlanningChartContainer'

const MIN_RANGE_HOURS = 24
const MAX_RANGE_HOURS = 24 * 7

interface LayoutDailyPlanningProps {
  updateSliderDefaultRange: (range: number) => void
  sliderDefaultRange: number
  boundary: MomentRange
}

export const LayoutDailyPlanning: React.FC<LayoutDailyPlanningProps> = ({
  updateSliderDefaultRange,
  sliderDefaultRange,
  boundary
}) => {
  const plantCode = useUrlParam('plantCode')
  const {selectedTimeframe} = useSelectedTimeframe()
  if (!selectedTimeframe) {
    throw new Error('selectedTimeframe is not defined')
  }

  prefetchPlannerQuery('peakLoadWindows', [
    {
      plantCode,
      from: selectedTimeframe[0].toISOString(),
      to: selectedTimeframe[1].toISOString()
    }
  ])

  const [startOfPlan, endOfPlan] = selectedTimeframe

  // prefetching required data
  prefetchPlannerQuery('materialStorageHistory', [
    plantCode,
    startOfPlan.toISOString(),
    endOfPlan.toISOString()
  ])
  prefetchPlannerQuery('materialsRecipes', [{plantCode}])
  prefetchPlannerQuery('assetsHistory', [
    plantCode,
    startOfPlan.toISOString(),
    endOfPlan.toISOString()
  ])

  return (
    <PlanningChartStartEndProvider
      sliderDefaultRange={sliderDefaultRange}
      sliderMinMaxRange={[MIN_RANGE_HOURS, MAX_RANGE_HOURS]}
      boundary={boundary}
      updateSliderDefaultRange={updateSliderDefaultRange}
      startOfPlan={startOfPlan}
      endOfPlan={endOfPlan}
    >
      <SelectedScheduleItemProvider>
        <PlanningChartScheduleDataProvider>
          <Stack spacing={2}>
            <PageProductionPlanningHeader actions={<DailyProductionPlanningActions />} />
            <ProductionPlanningChartContainer />
          </Stack>
        </PlanningChartScheduleDataProvider>
      </SelectedScheduleItemProvider>
    </PlanningChartStartEndProvider>
  )
}
