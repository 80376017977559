import {StockLevelOverwrite} from '@hconnect/common/types'
import {formatLocalizedDateMonth, formatLocalizedDateWithShortYear} from '@hconnect/uikit'
import {useTheme} from '@mui/material'
import {ScaleLinear, ScaleTime} from 'd3'
import {Moment} from 'moment-timezone'
import {useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {useDateScale, useLinearScale} from '../../../helpers/scale'
import {calculateNiceAxisSteps} from '../../../helpers/utils'
import {useCurrentTime} from '../../../hooks/useCurrentTime'
import {StockDevelopmentEntry} from '../../../interfaces/api'
import {DatetimeValue} from '../../../interfaces/common'
import {INDICATOR_SIZE} from '../incomingMaterials/IncomingMaterialIndicator'
import {StockDevelopmentTooltipContent} from '../StockDevelopmentTooltipContent'

export type StockDevelopmentChartConfig = {
  yScale: ScaleLinear<number, number, never>
  xScale: ScaleTime<number, number, never>
  verticalSteps: number[]
  minStockValue: number
  maxMinTargetLevelDatetimeValues: number
  minTargetLevelBaseValue: number
  formatDatetime: (datetime: Moment) => string
  formatTooltipContent: (datetimeValue: DatetimeValue) => JSX.Element
  timezoneId: string
}

export function useStockDevelopmentChartConfigData({
  stockData,
  listOfDays,
  timezoneId,
  minTargetLevels,
  storageCapacity,
  height,
  width
}: {
  stockData: {
    stockLevels: StockDevelopmentEntry & {combined: DatetimeValue[]}
    stockLevelsHourly: Record<string, number>
    stockOverwrites?: StockLevelOverwrite[]
  }
  listOfDays: Moment[]
  timezoneId: string
  minTargetLevels?: {weekend: number; week: number}
  storageCapacity?: number
  height: number
  width: number
}) {
  const {
    i18n: {language}
  } = useTranslation()
  const {spacing} = useTheme()
  const {stockLevelsHourly} = stockData

  const stockDevelopmentValues = Object.values(stockLevelsHourly)
  const maxStockValue: number = Math.max(...stockDevelopmentValues)
  const minStockValue: number = Math.min(...stockDevelopmentValues)
  const {steps: verticalSteps} = calculateNiceAxisSteps({
    numberOfSteps: 5,
    maxValue: Math.max(
      maxStockValue,
      ...(minTargetLevels ? [minTargetLevels.week, minTargetLevels.weekend] : []),
      ...(storageCapacity ? [storageCapacity] : [])
    ),
    minValue: Math.min(minStockValue, 0)
  })
  const maxDomainValue = verticalSteps[verticalSteps.length - 1]
  const minDomainValue = Math.min(0, minStockValue)

  // extract number of px from spacing
  const INCOMING_ORDER_DIAMETER = parseInt(spacing(INDICATOR_SIZE))

  const yScale = useLinearScale({
    domain: [minDomainValue, maxDomainValue],
    range: [height - INCOMING_ORDER_DIAMETER, INCOMING_ORDER_DIAMETER]
  })

  const xScale = useDateScale({
    domain: [listOfDays[0], listOfDays[listOfDays.length - 1]],
    range: [0, width]
  })

  const minTargetLevelDatetimeValues =
    minTargetLevels &&
    listOfDays.flatMap<DatetimeValue<string>>((day) => {
      // In order to avoid DST bugs we need to add utcOffset before checking the day
      const {week: weekValue, weekend: weekendValue} = minTargetLevels
      const utcOffset = day.clone().tz(timezoneId).utcOffset()
      const isSunday = day.clone().add(utcOffset, 'minutes').day() === 0
      const isMonday = day.clone().add(utcOffset, 'minutes').day() === 1
      const datetimeIso = day.toISOString()
      // weekend line should go from week value at start of saturday, then go strait to start of Monday with a weekend value forming a triangular shape
      // weekend value should always be greater then week walue, if not week values should be displayed
      if (weekendValue > weekValue) {
        if (isSunday) {
          return [
            {
              datetime: datetimeIso,
              value: (weekendValue + weekValue) / 2
            }
          ]
        }
        if (isMonday) {
          return [
            {
              datetime: datetimeIso,
              value: weekendValue
            },
            {
              datetime: datetimeIso,
              value: weekValue
            }
          ]
        }
      }
      return [
        {
          datetime: day.toISOString(),
          value: weekValue
        }
      ]
    })

  const maxMinTargetLevelDatetimeValues = Math.max(
    ...(minTargetLevelDatetimeValues || []).map(({value}) => value)
  )
  const minTargetLevelBaseValue = Math.min(
    minStockValue,
    ...(minTargetLevelDatetimeValues || []).map(({value}) => value)
  )

  const {stockOverwrites} = stockData

  const formatTooltipContent = useCallback(
    (datetimeValue: DatetimeValue) => (
      <StockDevelopmentTooltipContent
        canCreateStockOverwrite={false}
        datetimeValue={datetimeValue}
        stockOverwrites={stockOverwrites || []}
      />
    ),
    [stockOverwrites]
  )

  const currentTime = useCurrentTime({timezoneId})
  const formatDatetime = useCallback(
    (datetime: Moment) =>
      datetime.isSame(listOfDays[0])
        ? formatLocalizedDateWithShortYear(datetime, language)
        : formatLocalizedDateMonth(datetime, language),
    [listOfDays, language]
  )

  return {
    yScale,
    xScale,
    timezoneId,
    verticalSteps,
    minStockValue,
    maxMinTargetLevelDatetimeValues,
    minTargetLevelBaseValue,
    formatTooltipContent,
    formatDatetime,
    currentTime
  } as StockDevelopmentChartConfig
}
