import {Moment} from 'moment-timezone'
import {useCallback} from 'react'

import {StockDevelopment, StockDevelopmentEntry} from '../../../interfaces/api'
import {MomentRange} from '../../../selectors/time'
import {useUrlParam} from '../../useUrlParam'
import {STOCK_DEVELOPMENT_END_HOURS_PADDING} from '../constants'
import {useStoredMaterialIdsFromHistoryQuery} from '../materialStorage'
import {usePlannerQuery} from '../usePlannerQuery'

interface MaterialsStockLevelsQueryParams {
  planRange: MomentRange
  pointAtTime: Moment
  useOptimizedSchedule?: boolean
}

export const useMaterialsStockLevelsQuery = ({
  planRange,
  pointAtTime,
  useOptimizedSchedule
}: MaterialsStockLevelsQueryParams) => {
  const plantCode = useUrlParam('plantCode')
  // we do expect here input to be as string
  const beginningOfHour = pointAtTime.toISOString()
  const [planStart, planEnd] = planRange
  // BE doesn't support retrieving historical material stock levels, plantEnd should be > now
  const {data: materialIds} = useStoredMaterialIdsFromHistoryQuery({
    timeFrame: [planStart, planEnd]
  })

  const selectStockDevelopment = useCallback(
    (stockDevelopment: StockDevelopment | undefined) => {
      if (!stockDevelopment) {
        return undefined
      }

      const findValueAtRequestedTime = (
        materialId: string,
        {actual, forecast}: StockDevelopmentEntry
      ) => {
        // sensitive to the ISO date string formatting
        const combinedStockLevels = actual.concat(forecast)
        const entry = combinedStockLevels.find(({datetime}) => datetime === beginningOfHour)
        if (!entry) {
          throw new Error(
            `BE does not return valid data. Error finding datetimeValue ${beginningOfHour} at materialId ${materialId}`
          )
        }
        return entry.value
      }

      // return object keyed by materialId and value containing value at the beginningOfHour ( beginning of pointAtTime )
      return Object.fromEntries(
        Object.entries(stockDevelopment).map(([materialId, stockDevelopmentEntry]) => [
          materialId,
          findValueAtRequestedTime(materialId, stockDevelopmentEntry)
        ])
      )
    },
    [beginningOfHour]
  )

  return usePlannerQuery(
    'stockDevelopment',
    [
      {
        plantCode,
        materialIds: materialIds ?? [],
        useOptimizedSchedule: useOptimizedSchedule || false,
        from: planStart.startOf('hour').toISOString(),
        // We need an additional hour of stock levels data to display material orders correctly ( the same as in the useStockDevelopmentQuery )
        to: planEnd
          .clone()
          .add(STOCK_DEVELOPMENT_END_HOURS_PADDING, 'hour')
          .startOf('hour')
          .toISOString()
      }
    ],
    {
      enabled: Boolean(pointAtTime && materialIds?.length),
      select: selectStockDevelopment
    }
  )
}
