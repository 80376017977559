import {StockLevelOverwrite} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {Box} from '@mui/material'
import {Moment} from 'moment-timezone'
import React from 'react'

import {StockDevelopmentEntry} from '../../interfaces/api'
import {DatetimeValue} from '../../interfaces/common'
import type {IncomingMaterial} from '../../selectors/materialOrders'

import {useStockDevelopmentChartConfig} from './StockDevelopmentChartConfigProvider'
import {StockDevelopmentChartData} from './StockDevelopmentChartData'
import {StockDevelopmentChartDatetimeLabels} from './StockDevelopmentChartDatetimeLabels'

export interface StockDevelopmentChartContentProps {
  listOfDays: Moment[]
  stockData: {
    stockLevels: StockDevelopmentEntry & {combined: DatetimeValue[]}
    stockLevelsHourly: Record<string, number>
    stockOverwrites?: StockLevelOverwrite[]
  }
  chartRef: React.RefObject<HTMLDivElement>
  materialId: number
  minTargetLevels?: {weekend: number; week: number}
  deadStockLevel?: number
  storageCapacity?: number
  height?: number
  width: number
  yLabelWidth?: number
  incomingMaterialHourly?: Record<string, IncomingMaterial[]>
  openStockOverwriteDialog?: (
    datetimeValue: DatetimeValue<string, number>,
    currentOverwrite: StockLevelOverwrite | undefined
  ) => void
}

export const STOCK_DEVELOPMENT_CHART_DEFAULT_HEIGHT = 200

export const StockDevelopmentChartContent: React.FC<StockDevelopmentChartContentProps> = ({
  listOfDays,
  stockData,
  chartRef,
  materialId,
  openStockOverwriteDialog,
  minTargetLevels,
  deadStockLevel,
  storageCapacity,
  height = STOCK_DEVELOPMENT_CHART_DEFAULT_HEIGHT,
  width,
  yLabelWidth,
  incomingMaterialHourly
}) => {
  const {formatDatetime, timezoneId} = useStockDevelopmentChartConfig()

  return (
    <Box sx={{position: 'relative'}} {...dataTestId('stock_development_chart')}>
      <StockDevelopmentChartDatetimeLabels
        xSteps={listOfDays}
        yLabelWidth={yLabelWidth}
        timezoneId={timezoneId}
        formatDatetime={formatDatetime}
      >
        <StockDevelopmentChartData
          listOfDays={listOfDays}
          stockData={stockData}
          materialId={materialId}
          minTargetLevels={minTargetLevels}
          openStockOverwriteDialog={openStockOverwriteDialog}
          deadStockLevel={deadStockLevel}
          storageCapacity={storageCapacity}
          height={height}
          width={width}
          chartRef={chartRef}
          incomingMaterialHourly={incomingMaterialHourly}
        />
      </StockDevelopmentChartDatetimeLabels>
    </Box>
  )
}
