import {Moment} from 'moment-timezone'

import {usePlanRange} from '../../usePlanRange'
import {usePlantConfig} from '../../usePlantConfigData'
import {useUrlParam} from '../../useUrlParam'
import {STOCK_DEVELOPMENT_END_HOURS_PADDING} from '../constants'
import {useStoredMaterialIdsFromHistoryQuery} from '../materialStorage'
import {usePlannerQuery} from '../usePlannerQuery'

interface StockDevelopmentParams {
  materialId: number
  timeframe?: [start: Moment, end: Moment]
  useOptimizedSchedule?: boolean
}

export const useStockDevelopmentQuery = ({
  materialId,
  timeframe,
  useOptimizedSchedule
}: StockDevelopmentParams) => {
  const plantCode = useUrlParam('plantCode')
  const {timezone_id: timezoneId, created_at: createdAt} = usePlantConfig()
  const planTimeFrame = usePlanRange({timezoneId, createdAt})
  const {data: materialIds} = useStoredMaterialIdsFromHistoryQuery({timeFrame: planTimeFrame})

  const isEnabled = timeframe
  return usePlannerQuery(
    'stockDevelopment',
    [
      {
        plantCode,
        materialIds: materialIds ?? [],
        from: timeframe?.[0].toISOString(),
        // We need an additional hour of stock levels data to display material orders correctly
        to: timeframe?.[1]
          .clone()
          .add(STOCK_DEVELOPMENT_END_HOURS_PADDING, 'hour')
          .startOf('hour')
          .toISOString(),
        useOptimizedSchedule: useOptimizedSchedule || false
      }
    ],
    {
      enabled: Boolean(isEnabled && materialIds?.length),
      select: (stockDevelopment) => {
        if (!stockDevelopment) {
          return undefined
        }
        const {actual, forecast} = stockDevelopment[materialId] ?? []
        const stockDevelopmentCombined = actual.concat(forecast)
        const stockLevelsHourlyForMaterial = Object.fromEntries(
          stockDevelopmentCombined.map((entry) => [entry.datetime, entry.value])
        )
        return {
          stockLevels: {actual, forecast, combined: stockDevelopmentCombined},
          stockLevelsHourly: stockLevelsHourlyForMaterial
        }
      }
    }
  )
}
