import {MomentRange} from '../../../selectors/time'
import {useUrlParam} from '../../useUrlParam'
import {usePlannerQuery} from '../usePlannerQuery'

interface ScheduleQueryParams {
  isOptimized: boolean
  isDisabled?: boolean
  useErrorBoundary?: boolean
  range: MomentRange
}

export const useScheduleQuery = ({
  isOptimized,
  isDisabled = false,
  useErrorBoundary = true,
  range: [startOfPlan, endOfPlan]
}: ScheduleQueryParams) => {
  const plantCode = useUrlParam('plantCode')

  return usePlannerQuery(
    isOptimized ? 'optimizedSchedule' : 'schedule',
    [{plantCode, start: startOfPlan.toISOString(), end: endOfPlan.toISOString()}],
    {enabled: !isDisabled, useErrorBoundary}
  )
}
