import React from 'react'

import {useScheduleCostAvoidanceDialog} from '../../../../shared/components/providers/ScheduleCostAvoidanceDialogProvider'
import {PlannerFeature} from '../../../../shared/enums'
import {useTrackPlannerEvent} from '../../../../shared/helpers/trackPlannerEvents'
import {useScheduleQuery} from '../../../../shared/hooks/api/schedules/useScheduleQuery'
import {useSubmitSchedule} from '../../../../shared/hooks/api/schedules/useSubmitSchedule'
import {usePlannerFeature} from '../../../../shared/hooks/usePlannerFeature'
import {useUrlParam} from '../../../../shared/hooks/useUrlParam'
import {Electricity, Schedule} from '../../../../shared/interfaces/api'
import {PeakLoadWindow} from '../../../../shared/selectors'
import {usePlanningChartScheduleData} from '../PlanningChartScheduleDataProvider'
import {usePlanningChartStartEnd} from '../PlanningChartStartEndProvider'

import {ElectricityPurchaseConfirmation} from './ElectricityPurchaseConfirmation'
import {SubmissionButton} from './SubmissionButton'

interface SubmitPlanSectionProps {
  electricity: Electricity | undefined
  isSubmitAvailable: boolean
  peakLoadWindowsData: PeakLoadWindow[] | undefined
}

export const SubmitPlanSection: React.FC<SubmitPlanSectionProps> = React.memo(
  ({isSubmitAvailable, electricity, peakLoadWindowsData}) => {
    const plantCode = useUrlParam('plantCode')
    const trackPlannerEvent = useTrackPlannerEvent()

    const {startOfPlan, endOfPlan} = usePlanningChartStartEnd()

    const {mutate: submitSchedule} = useSubmitSchedule()
    const {isOptimized} = usePlanningChartScheduleData()
    const {data: schedule} = useScheduleQuery({
      range: [startOfPlan, endOfPlan],
      isOptimized,
      useErrorBoundary: false
    })
    const isCostAvoidanceEnabled = usePlannerFeature(PlannerFeature.CostAvoidance)
    const {setIsDialogOpen} = useScheduleCostAvoidanceDialog()

    const [showConfirmation, setShowConfirmation] = React.useState(false)

    const isSubmitDisabled =
      (peakLoadWindowsData && peakLoadWindowsData.some((plw) => plw.violated)) || !schedule

    const handleSubmitPlan = (schedule: Schedule) => {
      trackPlannerEvent({name: 'onSubmitPlanClick'})
      submitSchedule(
        {
          plantCode,
          scheduleId: schedule.scheduleId,
          updatedById: schedule.lastSaved.savedById,
          updatedOn: schedule.lastSaved.savedAt
        },
        {
          onSuccess: isCostAvoidanceEnabled ? () => setIsDialogOpen(true) : undefined
        }
      )
    }
    return (
      <>
        {showConfirmation && electricity && (
          <ElectricityPurchaseConfirmation
            show={showConfirmation}
            electricity={electricity}
            onClose={(submit: boolean) => {
              if (submit && schedule) {
                handleSubmitPlan(schedule)
              }
              setShowConfirmation(false)
            }}
          />
        )}
        {isSubmitAvailable && (
          <SubmissionButton
            id="submit-plan-button"
            buttonLabel="planning.submitSchedule"
            buttonTestId="button-schedule-save-and-submit"
            disabled={isSubmitDisabled}
            onClick={() => setShowConfirmation(true)}
          />
        )}
      </>
    )
  }
)
