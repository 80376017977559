import MockAdapter from 'axios-mock-adapter'
import moment from 'moment-timezone'

import {mockStore} from '../mockStore'

import {sleepResponse} from './utils'
export const enableDSEndpoints = (mock: MockAdapter) => {
  // GET Latest (Py)
  mock.onGet('/hcem/ui/v2/latest/0014').reply(() => {
    const {burglengenfeld} = mockStore.scenario()
    return sleepResponse([200, burglengenfeld.latest])
  })

  // GET Electricity prices (Py)
  mock.onGet('/hcem/ui/electricity/0014').reply((config) => {
    const {time_from: from, time_to: to} = config.params as {time_from: string; time_to: string}
    const {
      burglengenfeld: {electricityPrice}
    } = mockStore.scenario()

    const filteredActuals = electricityPrice.actual.filter((entry) =>
      moment.utc(entry.datetime).isBetween(from, to, 'second', '[)')
    )
    const filteredForecast = electricityPrice.forecast.filter((entry) =>
      moment.utc(entry.datetime).isBetween(from, to, 'second', '[)')
    )

    return sleepResponse([
      200,
      {...electricityPrice, actual: filteredActuals, forecast: filteredForecast}
    ])
  })

  // GET optimiziation calculation status (Py)
  mock.onGet('/hcem/ui/v2/optimizer/0014/status').reply(() => {
    const {burglengenfeld} = mockStore.scenario()
    return sleepResponse([200, burglengenfeld.optimizer_calc_status])
  })

  mock.onGet('/hcem/ui/v2/config/0014').reply(() => {
    const {
      burglengenfeld: {plantConfig}
    } = mockStore.scenario()
    return sleepResponse([200, plantConfig])
  })

  mock.onGet('/hcem/ui/peak_load_windows/0014').reply((config) => {
    const {time_from: from, time_to: to} = config.params as {time_from: string; time_to: string}
    const {
      burglengenfeld: {peakLoadWindows, backendErrors}
    } = mockStore.scenario()
    const filteredPeakLoadWindows = peakLoadWindows.filter(
      (entry) => moment.utc(entry.start).isBefore(to) && moment.utc(entry.end).isAfter(from)
    )
    if (backendErrors.peakLoadWindowsError) {
      return sleepResponse(backendErrors.peakLoadWindowsError)
    }
    return sleepResponse([200, filteredPeakLoadWindows])
  })

  return mock
}
