import type {Material, StockLevelOverwrite} from '@hconnect/common/types'
import {dataTestId, dateFormatter, useTranslation} from '@hconnect/uikit'
import {CardTitle} from '@hconnect/uikit/src/lib2'
import {Check, Close} from '@mui/icons-material'
import {
  Box,
  Dialog,
  DialogContent,
  Stack,
  Typography,
  FormControl,
  TextField,
  MenuItem,
  FormHelperText,
  Button
} from '@mui/material'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import moment, {Moment} from 'moment-timezone'
import {useMemo} from 'react'
import {Controller, useForm} from 'react-hook-form'

import {minValidator, requiredValidator} from '../../../helpers/validators'
import {useCurrentTime} from '../../../hooks/useCurrentTime'
import {usePlantConfig} from '../../../hooks/usePlantConfigData'
import {DatetimeValue} from '../../../interfaces/common'
import {getPlantUtcOffset, splitToIntervals} from '../../../selectors/time'
import {PlannerNumberInput} from '../../formComponents/PlannerNumberInput'

const getStringToMomentTransformer =
  (timezone_id: string, onChange: (...event: any[]) => void) =>
  (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const momentValue = moment(e.target.value).tz(timezone_id) // Parse ISO string to moment
    onChange(momentValue)
  }

export type StockOverwriteDialogProps = {
  stock: DatetimeValue<string, number> | undefined
  material: Material
  disabledDateEditing: boolean
  onOverwrite: (stock: DatetimeValue<string, number>) => void
  overwrite: StockLevelOverwrite | undefined
  isSaving: boolean
  onClose: () => void
}

export const StockOverwriteDialog = ({
  stock,
  onClose,
  onOverwrite,
  overwrite,
  isSaving,
  material,
  disabledDateEditing
}: StockOverwriteDialogProps) => {
  const {timezone_id: timezoneId} = usePlantConfig()
  const {
    t,
    i18n: {language}
  } = useTranslation()

  const currentTime = useCurrentTime({timezoneId})

  const defaultValues = useMemo(
    () => ({
      datetime: moment(stock?.datetime)
        .tz(timezoneId)
        .startOf('hour'),
      stockLevel: stock?.value ?? 0
    }),
    [stock?.datetime, stock?.value, timezoneId]
  )

  const {
    handleSubmit,
    control,
    formState: {isValid}
  } = useForm<{
    datetime: Moment
    stockLevel: number
  }>({
    mode: 'all',
    shouldFocusError: false,
    defaultValues
  })

  const submit = handleSubmit((data) => {
    if (!isValid) return

    onOverwrite({
      datetime: data.datetime.toISOString(),
      value: data.stockLevel
    })
  })

  return (
    <Dialog open={Boolean(stock)} onClose={onClose}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DialogContent
          sx={{width: ({spacing}) => spacing(44)}}
          {...dataTestId('stock_overwrite_dialog_content')}
        >
          <>
            <Box sx={{mb: 3}}>
              <CardTitle sx={{mb: 0}}>{t('stock.materials.correctStockLevel')}</CardTitle>
              <Typography variant="caption">{material.name}</Typography>
            </Box>
            <Stack spacing={3}>
              <Controller
                name="datetime"
                control={control}
                rules={{
                  ...requiredValidator(t),
                  validate: (value) => {
                    const valueAsDate = moment(value)
                    if (valueAsDate.isAfter(currentTime)) {
                      return t('common.dateMustBeInThePast')
                    }
                    return undefined
                  }
                }}
                render={({field: {ref, value, onChange}, fieldState: {error}}) => (
                  <Box>
                    <FormControl ref={ref} error={!!error}>
                      <Stack direction="column" spacing={3}>
                        <DatePicker
                          value={value}
                          onChange={onChange}
                          disabled={disabledDateEditing}
                          maxDate={currentTime}
                          renderInput={(props) => (
                            <TextField
                              {...props}
                              error={!!error}
                              inputProps={{...props.inputProps, readOnly: true}}
                              {...dataTestId('stock_overwrite_datetime_date')}
                            />
                          )}
                          label={t('planning.startDate')}
                        />
                        <TextField
                          sx={{width: ({spacing}) => spacing(30)}}
                          select
                          error={!!error}
                          label={t('planning.startTime')}
                          disabled={disabledDateEditing}
                          value={value.toISOString()}
                          onChange={getStringToMomentTransformer(timezoneId, onChange)}
                          {...dataTestId('stock_overwrite_datetime_time_select')}
                        >
                          {splitToIntervals(
                            value.clone().startOf('day'),
                            value.clone().startOf('day').add(1, 'day'),
                            60
                          ).map(({start: value}) => (
                            <MenuItem
                              key={value}
                              value={value}
                              {...dataTestId('stock_overwrite_datetime_select_item')}
                            >
                              {moment.utc(value).tz(timezoneId).format('HH:mm')}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Stack>
                      <FormHelperText>{error?.message}</FormHelperText>
                    </FormControl>
                  </Box>
                )}
              />
              <Controller
                control={control}
                name="stockLevel"
                rules={{...requiredValidator(t), ...minValidator(t, 0)}}
                render={({field: {ref, value, onChange}, fieldState: {error}}) => (
                  <PlannerNumberInput
                    inputRef={ref}
                    min={0}
                    value={value}
                    onChange={onChange}
                    error={error?.message}
                    label={t('planning.stockLevel')}
                    dataTestId="stock_overwrite_amount_input"
                  />
                )}
              />
              {overwrite && (
                <Stack>
                  <Typography variant="caption">
                    {dateFormatter(
                      moment(overwrite.createdAt).tz(timezoneId),
                      language,
                      getPlantUtcOffset(timezoneId)
                    )}
                  </Typography>
                  <Typography variant="caption">
                    {t('stock.materials.lastCorrectionBy', {name: overwrite.updatedBy})}
                  </Typography>
                </Stack>
              )}
              <Box sx={{display: 'flex', mt: 2, justifyContent: 'flex-end'}}>
                <Button
                  color="primary"
                  variant="text"
                  {...dataTestId('stock_overwrite_dialog_cancel_button')}
                  sx={{
                    minWidth: (theme) => theme.spacing(15)
                  }}
                  onClick={onClose}
                  startIcon={<Close />}
                >
                  {t('common.cancel')}
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  {...dataTestId('stock_overwrite_dialog_save_button')}
                  sx={{
                    minWidth: (theme) => theme.spacing(15)
                  }}
                  onClick={submit}
                  disabled={isSaving}
                  startIcon={<Check />}
                >
                  {t('common.save')}
                </Button>
              </Box>
            </Stack>
          </>
        </DialogContent>
      </LocalizationProvider>
    </Dialog>
  )
}
